import { MailTwoTone } from '@ant-design/icons';
import type { ColumnsType } from '@iib/pandora-box';
import { Badge, Button, Checkbox, CheckboxGroup, CreateEditLayout, Modal, Table, TextArea, useTranslation } from '@iib/pandora-box';
import { List, Tooltip } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { TableRowSelection } from 'antd/es/table/interface';
import React, { FC, useEffect, useState, useRef } from 'react';
import type { SortOrder } from 'antd/es/table/interface';
import { useHistory } from 'react-router-dom';
import Loading from '../../../components/Loading';
import type { CustomerWithKey, PaginatedList, SelectionType } from '../../../types';
import { Customer, Role, User } from '../../../types';
import { mapSelectionToCustomers, TestID } from '../../../utils';
import { useCreateUser, useGetAllCustomers, useGetCustomer, useGetUser, useListUserRoles, useUpdateUser } from '../hooks';
import * as S from './styles';
import { UserActions } from '../../../components/common';

const getColumns = (sortedInfo: { field?: string; order?: string }) => {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'left',
      sorter: true,
      sortOrder: (sortedInfo.field === 'id' ? (sortedInfo.order as SortOrder) : 'ascend') as SortOrder,
    },
    {
      title: 'Name',
      dataIndex: 'companyName',
      align: 'center',
    },
    {
      title: 'Ort',
      dataIndex: 'city',
      render: (value) => <div style={{ textAlign: 'center', maxHeight: '100%' }}>{value ? <p>{value}</p> : <Badge status={'error'} />}</div>,
      align: 'center',
    },
    {
      title: 'PLZ',
      dataIndex: 'zipcode',
      render: (value) => <div style={{ textAlign: 'center' }}>{value ? <p>{value}</p> : <Badge status={'error'} />}</div>,
      align: 'center',
    },
  ] as ColumnsType<CustomerWithKey>;
};

export const UserCreate: FC = () => {
  const {
    location: { pathname },
    push,
  } = useHistory();

  const id = pathname.split('/')[3];

  const [initialLoader, setInitialLoader] = useState<boolean>(true);

  const [showModal, setShowModal] = useState(false);
  const [customerLoading, setCustomerLoading] = useState<boolean>(true);
  const [customerModalData, setCustomerModalData] = useState<PaginatedList<CustomerWithKey>>({ page: 1, numberOfItems: 0, numberOfPages: 0, items: [] });
  const [allCustomersData, setAllCustomersData] = useState<CustomerWithKey[]>([]);
  const [finalSelectedRows, setFinalSelectedRows] = useState<CustomerWithKey[]>();
  const [query, setQuery] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [selectionType, setSelectionType] = useState<SelectionType>('multi');

  const [roles, setRoles] = useState<Array<Role>>();
  const [user, setUser] = useState<User>();
  const [finalSelectedCompany, setFinalSelectedCompany] = useState<Customer>();
  const [selectedRoles, setSelectedRoles] = useState<string[]>();
  const [selection, setSelection] = useState<React.Key[]>([]);
  const [selectedToggle, setSelectedToggle] = useState<boolean>(false);
  const [sortedInfo, setSortedInfo] = useState<{ field?: string; order?: string }>({ field: 'id', order: 'ascend' });

  const { performAction: getRoles } = useListUserRoles();
  const { performAction: getAllCustomers } = useGetAllCustomers();
  const { performAction: getCustomer } = useGetCustomer();
  const { performAction: getUser } = useGetUser();
  const { performAction: createUser } = useCreateUser();
  const { performAction: updateUser } = useUpdateUser();
  const { t } = useTranslation();

  const fetchData = async () => {
    if (id) {
      const fetchedUser = await getUser({ id });
      if (fetchedUser) setUser(fetchedUser);
    }

    const result = await getRoles();
    setRoles(result);
  };

  const fetchCustomers = async (searchQuery?: string, page: number = currentPage, sorter?: { field?: string; order?: string }) => {
    setCustomerLoading(true);

    const sort: Record<string, string> = {};
    const effectiveSorter = sorter || { field: 'id', order: 'ascend' };

    if (effectiveSorter.field) {
      // Convert antd's 'ascend'/'descend' to 'ASC'/'DESC'
      const sortOrder = effectiveSorter.order === 'ascend' ? 'ASC' : 'DESC';
      sort[effectiveSorter.field] = sortOrder;
    }

    try {
      const requestedPage = page;

      const fetchedCustomers = await getAllCustomers({
        query: searchQuery,
        page: requestedPage,
        itemsPerPage: 10,
        sort: Object.keys(sort).length > 0 ? sort : undefined,
      });

      const processedItems = fetchedCustomers.items
        .filter((item) => item.active !== false)
        .map((item) => {
          return { ...item, key: item.id };
        });

      const processedData: PaginatedList<CustomerWithKey> = {
        ...fetchedCustomers,
        items: processedItems,
        page: requestedPage,
      };

      if (!allCustomersData?.length && !searchQuery) {
        setAllCustomersData(processedItems);
      }

      setCustomerModalData(processedData);
    } catch (error) {
      console.error('Error fetching customers:', error);
    } finally {
      setCustomerLoading(false);
    }
  };

  const onCustomerSelect = (customer: CustomerWithKey, isSelect: boolean, _selected: CustomerWithKey[]) => {
    const exists = allCustomersData.find((c) => c.id === customer.id);
    if (!exists) {
      setAllCustomersData((prev) => [...prev, customer]);
    }
    if (selectionType === 'single' && isSelect) {
      setSelection([customer.id]);
      return;
    }

    if (!isSelect) {
      setSelection((current) => current?.filter((c) => c !== customer.id));
      return;
    }

    setSelection((current) => [...current, customer?.id]);
  };

  const rowSelection: TableRowSelection<CustomerWithKey> = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: selection,
    onSelect: onCustomerSelect,
    type: 'checkbox',
  };

  const handleOk = () => {
    setShowModal(false);
    const filteredData = mapSelectionToCustomers(selection, allCustomersData, customerModalData.items);

    if (selectionType === 'multi') {
      setFinalSelectedRows(filteredData);
    } else {
      setFinalSelectedCompany(filteredData[0]);
    }
    setSelection([]);
    setSelectedToggle(false);
  };

  const handleCancel = () => {
    setSelection([]);
    setShowModal(false);
    setSelectedToggle(false);
    setCurrentPage(1);
  };

  const debouncedSearch = (value: any) => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      setCurrentPage(1);
      fetchCustomers(value, 1, sortedInfo);

      searchTimeoutRef.current = null;
    }, 1500);
  };

  const onSearch = (value: any) => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
      searchTimeoutRef.current = null;
    }

    setCurrentPage(1);
    fetchCustomers(value, 1, sortedInfo);
  };

  const onRolesChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedRoles(checkedValues.map((value) => value.toString()));
  };

  const handleSubmit = async ({ givenName, familyName, email, phoneNumber, notes }: Partial<User>) => {
    const body = {
      givenName,
      familyName,
      email,
      phoneNumber,
      notes,
      roles: selectedRoles,
      customer: Number(finalSelectedCompany?.id),
      customers: finalSelectedRows?.map((row) => Number(row.id)),
    };
    if (id && user) {
      await updateUser({ id, body, onSuccess: () => push(`/users/detail/${id}`) });
    } else {
      await createUser({ body, onSuccess: (created) => push(`/users/detail/${created.id.replace('auth0|', '')}`) });
    }
  };

  useEffect(() => {
    fetchCustomers(undefined, currentPage, sortedInfo).then(() => fetchData().then(() => setInitialLoader(false)));

    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [currentPage]);

  const setUserSelectedCompany = async (user: User) => {
    if (!user.customer) return;

    const exists = [user.customer].map((c) => allCustomersData.filter((item) => Number(item.id) === c)).flat(1)?.[0];
    if (exists) {
      setFinalSelectedCompany(exists);
      return;
    }

    const fetchedCustomer = await getCustomer({ id: user.customer });
    if (!fetchedCustomer) return;

    setAllCustomersData((prev) => [...prev, fetchedCustomer]);
    setFinalSelectedCompany(fetchedCustomer);
  };

  const setUserSelectedCompanies = async (user: User) => {
    if (!user.customers?.length) return;
    const existing = user.customers.map((c) => allCustomersData.filter((item) => Number(item.id) === c)).flat(1);
    if (existing.length === user.customers.length) {
      setFinalSelectedRows(existing);
      return;
    }

    const missing = user.customers.filter((customerId) => !existing.find((customer) => customer.id === String(customerId)));
    const missingCustomersPromises = missing.map((customerId) => getCustomer({ id: customerId }));
    const fetchedCustomers = missingCustomersPromises ? await Promise.all(missingCustomersPromises) : [];
    const missingCustomers = fetchedCustomers.filter((customer) => !!customer) as Customer[];

    setFinalSelectedRows(existing.concat(missingCustomers));
    setAllCustomersData((prev) => [...prev, ...missingCustomers]);
  };

  useEffect(() => {
    if (!user || customerLoading || finalSelectedRows?.length) return;
    setSelectedRoles(user?.roles?.map((role) => role.name));
    setUserSelectedCompany(user).then(() => {
      setUserSelectedCompanies(user);
    });
  }, [user, customerLoading]);

  return (
    <S.ViewWrapper data-testid={TestID.UserCreate}>
      <Modal
        title={
          <S.ModalHeader>
            <S.Heading>{selectionType === 'multi' ? t('select_customers') : t('select_customer')}</S.Heading>
            <S.ModalSearch
              value={query}
              onChange={(e) => {
                const newValue = e.target.value;
                setQuery(newValue);
                debouncedSearch(newValue);
              }}
              placeholder="Search table"
              onSearch={onSearch}
            />
          </S.ModalHeader>
        }
        open={showModal}
        onCancel={handleCancel}
        onOk={handleOk}
        width="800px"
        closable={false}
        destroyOnClose
      >
        <Table
          rowSelection={{
            ...rowSelection,
            columnTitle: (
              <Tooltip placement="topLeft" title={t('toggle_selected')}>
                <S.ToggleSelected checked={selectedToggle} onChange={() => setSelectedToggle(!selectedToggle)} />
              </Tooltip>
            ),
          }}
          columns={getColumns(sortedInfo)}
          dataSource={selectedToggle ? customerModalData.items.filter((item) => selection?.includes(item.id)) : customerModalData.items}
          loading={customerLoading}
          onChange={(pagination, filters, sorter) => {
            if (sorter && !Array.isArray(sorter)) {
              const { field, order } = sorter;
              if (field === 'id') {
                const effectiveOrder = order || 'ascend';
                setSortedInfo({ field: field as string, order: effectiveOrder });
                if (!selectedToggle) {
                  fetchCustomers(query, currentPage, { field: field as string, order: effectiveOrder });
                }
              }
            }
          }}
          pagination={{
            current: customerModalData.page || currentPage,
            total: selectedToggle ? customerModalData.items.filter((item) => selection?.includes(item.id)).length : customerModalData.numberOfItems,
            showSizeChanger: false,
            pageSize: 10,
            onChange: (page) => {
              if (page === currentPage) return;

              if (!selectedToggle) {
                const targetPage = page;
                setCustomerLoading(true);
                setCurrentPage(targetPage);

                setTimeout(() => {
                  fetchCustomers(query, targetPage, sortedInfo);
                }, 500);
              } else {
                setCurrentPage(page);
              }
            },
          }}
        />
      </Modal>
      <CreateEditLayout
        width="100%"
        columns={2}
        loading={initialLoader}
        Loader={<Loading />}
        header={{
          title: pathname === '/users/create' ? t('new_user') : t('edit_user'),
          rightButton: user ? <UserActions id={id} blocked={user?.blocked} onOk={() => push(`/users/detail/${id}`)} isEdit /> : <></>,
        }}
        record={user}
        handleSubmit={handleSubmit}
        buttonLabel={t('submit')}
        fields={{
          givenName: {
            label: t('given_name'),
            span: 2,
            status: (name) => (!name ? 'warning' : ''),
          },
          familyName: {
            label: t('family_name'),
            span: 2,
            status: (name) => (!name ? 'warning' : ''),
          },
          email: { label: t('email_c'), status: (email) => (email.indexOf('@') === -1 ? 'error' : ''), span: 2, suffix: <MailTwoTone /> },
          phoneNumber: { label: t('phone_number'), span: 2, status: (number) => (!number ? 'warning' : '') },
          customers: {
            label: t('can_access_customers'),
            span: 2,
            render: () => {
              return (
                <>
                  {!!finalSelectedRows?.length && (
                    <List
                      dataSource={finalSelectedRows}
                      style={{
                        marginTop: '15px',
                        marginBottom: '5px',
                        marginRight: '10px',
                      }}
                      renderItem={(item) => (
                        <Tooltip placement="left" title={`ID: ${item.id}`}>
                          <S.ListItem key={item.id}>
                            {item.companyName} {item.city}
                          </S.ListItem>
                        </Tooltip>
                      )}
                    />
                  )}
                  <S.CustomerButtonWrapper>
                    <Button
                      onClick={() => {
                        setSelectionType('multi');
                        setSelection(finalSelectedRows?.map((row) => row?.id) || []);
                        setShowModal(true);
                      }}
                      style={{
                        height: '40px',
                        marginTop: '15px',
                        marginBottom: '5px',
                        marginRight: '10px',
                      }}
                    >
                      {t('select_customers')}
                    </Button>
                  </S.CustomerButtonWrapper>
                </>
              );
            },
          },
          customer: {
            label: t('belongs_to_customer'),
            span: 2,
            render: () => (
              <>
                {finalSelectedCompany !== undefined && (
                  <List
                    dataSource={[finalSelectedCompany]}
                    renderItem={(item) => (
                      <Tooltip placement="left" title={`ID: ${item.id}`}>
                        <S.ListItem key={item.id}>
                          {item.companyName} {item.city}
                        </S.ListItem>
                      </Tooltip>
                    )}
                  />
                )}
                <S.CustomerButtonWrapper>
                  <Button
                    onClick={() => {
                      setSelectionType('single');
                      setSelection(finalSelectedCompany ? [finalSelectedCompany.id] : []);
                      setShowModal(true);
                    }}
                    style={{
                      height: '40px',
                      marginTop: '15px',
                      marginBottom: '5px',
                      marginRight: '10px',
                    }}
                  >
                    {t('select_customer')}
                  </Button>
                </S.CustomerButtonWrapper>
              </>
            ),
          },
          roles: {
            label: t('roles_c'),
            span: 2,
            render: () => (
              <CheckboxGroup style={{ width: '100%' }} onChange={onRolesChange} defaultValue={selectedRoles}>
                <S.RoleList>
                  {roles?.map((element) => (
                    <div key={element.id}>
                      <Checkbox value={element.name}>{element?.description}</Checkbox>
                    </div>
                  ))}
                </S.RoleList>
              </CheckboxGroup>
            ),
          },
          notes: {
            label: t('register_tools'),
            span: 2,
            render: (fields, setFields) => (
              <TextArea
                value={fields?.notes}
                onChange={(e) => {
                  const notes = e.target.value;
                  setFields((current) => ({ ...current, notes }));
                }}
              />
            ),
          },
        }}
      />
    </S.ViewWrapper>
  );
};

export default UserCreate;

import { useContext, useState } from 'react';
import { ApiClient } from '../../../services/api';
import { CustomerApiUrls, getAuthHeaders } from '../../../utils';
import { Customer, PaginatedList } from '../../../types';
import { useTranslation, PandoraContext } from '@iib/pandora-box';

type Params = {
  query?: string;
  page?: number;
  itemsPerPage?: number;
  sort?: Record<string, string>;
};

type HookReturnType = {
  performAction: (params: Params) => Promise<PaginatedList<Customer>>;
  loading: boolean;
  totalPages: number;
  totalItems: number;
};

export const useGetAllCustomers = (): HookReturnType => {
  const [loading, setLoading] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const { t } = useTranslation();
  const { messageApi } = useContext(PandoraContext);

  const performGetAllCustomers = async ({ query, itemsPerPage = 10, page = 1, sort }: Params): Promise<PaginatedList<Customer>> => {
    try {
      setLoading(true);

      const params: Record<string, any> = { query, itemsPerPage, page };

      if (sort) {
        Object.entries(sort).forEach(([key, value]) => {
          params[`sort[${key}]`] = value;
        });
      }

      const { data } = await ApiClient.get(CustomerApiUrls.list, {
        headers: getAuthHeaders(),
        params,
      });

      setTotalItems(data.numberOfItems);
      setTotalPages(data.numberOfPages);

      const responseWithCorrectPage = {
        ...data,
        page,
      };

      setLoading(false);
      return responseWithCorrectPage;
    } catch (error) {
      setLoading(false);
      messageApi?.error(t('api_error_generic'));
      return { page, items: [], numberOfItems: 0, numberOfPages: 0 };
    }
  };

  return {
    loading,
    performAction: performGetAllCustomers,
    totalPages,
    totalItems,
  };
};
